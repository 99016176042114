import React, { useContext, useLayoutEffect } from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/react';
import { ThemeContext } from '../components/theme-provider';
import SEO from '../components/seo';
import FullImage from '../components/full-image';
import Button from '../components/button';
import PortableText from '../components/portable-text';
import PDF from '../components/pdf';
import Gallery from '../components/gallery';
import Accordion from '../components/accordion';
import { formatDates } from '../utility';

export default function Community({ data }) {
  const theme = useContext(ThemeContext);

  useLayoutEffect(() => {
    theme.setGrayFooter(true);
  }, []);

  console.log(data.programs.nodes);

  data.programs.nodes = data.programs.nodes
    .filter((program) => program.startDate && program.featureImage?.asset)
    .filter((program) => ((new Date(program.startDate).valueOf() > new Date().valueOf()) || (new Date(program.endDate).valueOf() > new Date().valueOf())));

  return (
    <div
      css={css`
        background-color: var(--white);
        padding-bottom: var(--bigMargin);
      `}
    >
      <SEO
        title="Community"
        description={(data.community._rawContent) ? data.community._rawContent.map(el => el.children).flat().map((el) => el?.text)[0] : null}
        imageID={(data.community.featureImage) ? data.community.featureImage.asset._id : null}
      />
      {data.programs.nodes.length === 0 && <FullImage
        text={`
          Community/
        `}
        image={data.community.featureImage}
        includeTitle
        noMargin
      />}
      {data.programs.nodes.length > 0 && data.programs.nodes
        .map((program, i) => (
          <FullImage
            key={i}
            text={`Community/\u200b${program.title}/\u200b${formatDates(program.startDate, program.endDate)}`}
            image={program.featureImage}
            link={`/program/${program.slug.current}`}
            noMargin
          />
        ))
      }
      <div
        css={css`
          margin: 0 var(--margin);
          margin-top: calc(var(--margin) * 2);
        `}
      >
        <div
          css={css`
            margin: 0 var(--margin);
            margin-bottom: var(--bigMargin);
          `}
        >
          <Button
            text="What's on"
            link="/whats-on#community"
            large
          />
        </div>
        <div
          className="type--45"
          css={css`
            margin-bottom: var(--bigMargin);
          `}
        >
          <PortableText
            blocks={data.community._rawContent}
          />
        </div>
        {data.community.engagementReports.length > 0 && <div>
          <div
            className="type--20"
          >
            Community engagement reports:
          </div>
          <div
            css={css`
              margin-bottom: var(--bigMargin);
            `}
          >
            {data.community.engagementReports.length > 0 && data.community.engagementReports
              .map((pdf, i) => (
                <PDF
                  key={i}
                  link={pdf.file.asset.url}
                  text={pdf.title}
                />
              ))
            }
          </div>
        </div>}
        <div
          css={css`
            margin-bottom: var(--bigMargin);
          `}
        >
          {data.community.images.length > 0 && <Gallery images={data.community.images} />}
        </div>
        <div>
          <Accordion
            title="Community Reference Group"
          >
            {data.community.referenceGroup.length > 0 && data.community.referenceGroup
              .map((person, i) => (
                <div
                  key={i}
                >
                  {person._rawContent && <div
                    css={css`
                      text-decoration: underline;
                      cursor: pointer;
                      transition: color 0.6s var(--curve);

                      &:hover {
                        color: var(--primary);
                        transition: color 0s;
                      }
                    `}
                    onClick={() => { theme.setModalContent(person._rawContent); theme.setShowModal(true); }}
                  >
                    <span css={css`text-transform: uppercase;`}>{person.name}</span>{(person.position) ? `, ${person.position}` : ''}
                  </div>}
                  {!person._rawContent && <div>
                    <span css={css`text-transform: uppercase;`}>{person.name}</span>{(person.position) ? `, ${person.position}` : ''}
                  </div>}
                </div>
              ))
            }
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export const query = graphql`
  query {
    community: sanityCommunity(_id: { regex: "/(drafts\\\\.)?community/" }) {
      featureImage {
        ...Image
        asset {
          description
        }
      }
      _rawContent(resolveReferences: {maxDepth: 10})
      engagementReports {
        title
        file {
          asset {
            url
          }
        }
      }
      images {
        ...Image
        asset {
          description
        }
      }
      referenceGroup {
        name
        position
        _rawContent(resolveReferences: {maxDepth: 10})
      }
    }
    programs: allSanityProgram(filter: {format: {elemMatch: {slug: {current: {eq: "community"}}}}, private: {ne: true}}, sort: {fields: startDate, order: DESC}) {
      nodes {
        slug {
          current
        }
        featureImage {
          ...Image
        }
        title
        startDate
        endDate
      }
    }
  }
`

